import React, { Component } from "react";
import { utils } from "../../../../helpers/utils";
import "./InvoiceReview.css";
import Button from "../../../Common/Button/Button";
import { applicationService } from "../../../../services/applicationService";
import LoaderComponent from "../../../Common/Loader/Loader";
import Input from "../../../Common/Input/Input";
import SyncIcon from "../../../../images/SyncIcon.svg";

export default class InvoiceReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceReviewDecision: "",
      showMessage: false,
      reviewQuestions: "",
      updatedTime: true,
      review: {
        reviewNote: "",
        questions: [
          { question: "Does the Company Name (Applicant Details) match the name in the Incentive Invoice?", answer: "No" },
          { question: "Does IESO Address in invoice match IESO Address in Portal?", answer: "No" },
          { question: "Does Participant Company Address in invoice match Participant Company Address in Portal?", answer: "No" },
          { question: "Does Participant HST # in invoice match Participant HST # in Portal?", answer: "" },
          { question: "Does incentive + HST amount in invoice match incentive + HST amount calculated by Dolphin?", answer: "No" },
          {
            question:
              "Does Participant Incentive Amount (without HST) in invoice match Participant Incentive Amount (without HST) in Portal?",
            answer: "No",
          },
          { question: "Does HST amount in invoice match HST amount calculated by Dolphin?", answer: "No" },
          { question: "Does incentive + HST amount in invoice match incentive + HST amount calculated by Dolphin?", answer: "No" },
          { question: "Is an issue date present on the incentive invoice?", answer: "No" },
        ],
      },
    };
  }
  componentDidMount = () => {
    const { application } = this.props;
    let reviewQuestions = [
      {
        invoiceItems: "Participant Company Name",
        criteria: `${application.companyName}`,
        question: "Does the Company Name (Applicant Details) match the name in the Incentive Invoice?",
        inputType: "toggle",
        responses: ["Yes", "No"],
      },
      {
        invoiceItems: "IESO Address",
        criteria: "120 Adelaide St W, Suite 1600, Toronto, ON, M5H 1T1",
        question: "Does IESO Address in invoice match IESO Address in Portal?",
        inputType: "toggle",
        responses: ["Yes", "No"],
      },
      {
        invoiceItems: "Participant’s Company Address",
        criteria: `${application.applicantAddress1}`,
        question: "Does Participant Company Address in invoice match Participant Company Address in Portal?",
        inputType: "toggle",
        responses: ["Yes", "No"],
      },
      {
        invoiceItems: "Participant’s Company HST#",
        criteria: `${application.hst}`,
        question: "Does Participant HST # in invoice match Participant HST # in Portal?",
        inputType: "dropdown",
        responses: [
          { label: "N/A", value: "N/A" },
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ],
      },
      {
        invoiceItems: "Participant Incentive Amount (without HST)",
        criteria: `${utils.formatAsCAD(application.totalActualAppIncentive)}`,
        question: "Does Participant Incentive Amount (without HST) in invoice match Participant Incentive Amount (without HST) in Portal?",
        inputType: "toggle",
        responses: ["Yes", "No"],
      },
      {
        invoiceItems: "HST Incentive Amount",
        criteria: `${utils.formatAsCAD(application.totalActualAppIncentive ? (application.totalActualAppIncentive * 13) / 100 : 0)}`,
        question: "Does HST amount in invoice match HST amount calculated by Dolphin?",
        inputType: "toggle",
        responses: ["Yes", "No"],
      },
      {
        invoiceItems: "Participant Incentive Amount",
        criteria: `${utils.formatAsCAD(application.totalActualAppIncentive + (application.totalActualAppIncentive * 13) / 100)}`,
        question: "Does incentive + HST amount in invoice match incentive + HST amount calculated by Dolphin?",
        inputType: "toggle",
        responses: ["Yes", "No"],
      },
      {
        invoiceItems: "Issue Date",
        criteria: "-",
        question: "Is an issue date present on the incentive invoice?",
        inputType: "toggle",
        responses: ["Yes", "No"],
      },
    ];
    this.setState({
      reviewQuestions: reviewQuestions,
    });
  };
  handleNotesChange = (name, value) => {
    this.setState({
      review: {
        ...this.state.review,
        reviewNote: value,
      },
    });
  };
  handleToggleChange = (name, val) => {
    const { questions } = this.state.review;
    let reviewAns = questions.map((item) => {
      if (item.question === name) {
        item.answer = val === true ? "Yes" : "No";
      }
      return item;
    });
    this.setState({
      review: {
        ...this.state.review,
        questions: reviewAns,
      },
    });
  };
  onStatusChange = (value, action) => {
    const { application } = this.props;
    const { reviewQuestions } = this.state;
    const { questions } = this.state.review;
    let newQuestions = [];
    if( value.value === "N/A" ) {
      newQuestions = reviewQuestions.map((question) => {
        if ( question.invoiceItems === "HST Incentive Amount" ) {
          question.criteria = "$0.0"
        }
        if ( question.invoiceItems === "Participant Incentive Amount" ) {
          question.criteria = `${utils.formatAsCAD(application.totalActualAppIncentive)}`
        }
        return question;
      })
    } else {
      newQuestions = reviewQuestions.map((question) => {
        if ( question.invoiceItems === "HST Incentive Amount" ) {
          question.criteria = `${utils.formatAsCAD(application.totalActualAppIncentive ? (application.totalActualAppIncentive * 13) / 100 : 0)}`;
        }
        if ( question.invoiceItems === "Participant Incentive Amount" ) {
          question.criteria = `${utils.formatAsCAD(application.totalActualAppIncentive + (application.totalActualAppIncentive * 13) / 100)}`;
        }
        return question;
      })
    }
    let reviewAns = questions.map((item) => {
      if (item.question === action.name) {
        item.answer = value;
      }
      return item;
    });
    this.setState({
      reviewQuestions: newQuestions,
      review: {
        ...this.state.review,
        questions: reviewAns,
      },
    });
  };
  updateAppSync = async (appId) => {
    this.setState({ updatedTime: false });
    try {
      await applicationService.syncApplicationSummaryRetrofit(appId).then(() => {
        this.props.onSyncComplete();
        this.setState({ updatedTime: true });
      });
    } catch (err) {
      console.log("Updating App", err);
    }
  };
  selectReviewerOption = (val) => {
    this.setState({
      invoiceReviewDecision: val,
    });
  };
  completeReview = () => {
    this.setState({ showMessage: true });
  };
  renderReviewDetails = () => {
    const { reviewQuestions, review, showMessage } = this.state;
    let toggleValue = {};
    review &&
      review.questions.map((i) => {
        toggleValue[i.question] = i.answer && i.answer.label === "N/A" ? "N/A" : i.answer === "Yes" ? true : false;
      });
    let rows =
      reviewQuestions &&
      reviewQuestions.map((question, index) => {
        return (
          <div className="contractor-review-details">
            {showMessage ? "" : <div className="review-detail">{index + 1}</div>}
            <div className="review-detail">{question.invoiceItems}</div>
            <div className="review-detail">{question.criteria}</div>
            <div className="review-detail">{question.question}</div>
            <div className="review-detail review-status">
            {question.inputType === "toggle" ? (
                (index === 4 && toggleValue["Does Participant HST # in invoice match Participant HST # in Portal?"] === "N/A" &&
                  question.question === "Does HST amount in invoice match HST amount calculated by Dolphin?") ? (
                  <Input
                    type="text"
                    disabled={
                      showMessage ||
                      (toggleValue["Does Participant HST # in invoice match Participant HST # in Portal?"] === "N/A" &&
                        question.question === "Does HST amount in invoice match HST amount calculated by Dolphin?")
                    }
                    value="N/A"
                    onChange={""}
                  />)
                  : <Input
                    type="switch"
                    disabled={
                      showMessage ||
                      (toggleValue["Does Participant HST # in invoice match Participant HST # in Portal?"] === "N/A" &&
                        question.question === "Does HST amount in invoice match HST amount calculated by Dolphin?")
                    }
                    name={question.question}
                    checked={
                      toggleValue[question.question] ||
                      (toggleValue["Does Participant HST # in invoice match Participant HST # in Portal?"] === "N/A" &&
                        question.question === "Does HST amount in invoice match HST amount calculated by Dolphin?")
                    }
                    onChange={this.handleToggleChange}
                  />
              ) : (
                <Input                            
                  type="dropdown"
                  // disabled={showMessage}
                  name={question.question}
                  onChange={this.onStatusChange}
                  value={review.questions.map((item) => {
                    if (item.question == question.question) {
                      return item.answer;
                    }
                  })}
                  options={question.responses.map((val) => val)}
                  placeholder={"Select answer from list"}
                />
              )}
            </div>
          </div>
        );
      });
    return rows;
  };
  downloadInvoice = (invoice) => {
    window.open(invoice.url, "download_window");
  };
  confrimReview = () => {
    const { review, invoiceReviewDecision } = this.state;
    const { application, adminUserInfo } = this.props;
    let updateObj = {
      actionTaker: `${adminUserInfo.firstName} ${adminUserInfo.lastName}`,
      flag: invoiceReviewDecision,
      stage: "Payment",
      ...review,
    };
    if (updateObj.questions && updateObj.questions[2].answer.label === "N/A") {
      /*updateObj.questions[5].answer = "Yes";*/
      updateObj.questions[5].answer = "N/A"
    }
    let newArray = {};
    updateObj.questions.map((question, index) => {
      newArray[index] = question;
    });
    updateObj.questions = newArray;
    applicationService.retrofitInvoiceReview(application.appNo, updateObj).then((res) => {
      this.setState(() => {
        this.props.closeActionModal(true);
      });
    });
  };
  render() {
    const { application, closeActionModal, adminUserInfo } = this.props;
    const { review, invoiceReviewDecision, reviewLoading, showMessage, updatedTime } = this.state;
    const { reviewNote } = review;
    if (reviewLoading) {
      return <LoaderComponent></LoaderComponent>;
    }
    return (
      <div>
        <div className="review-container">
          {application ? (
            <React.Fragment>
              <div className="section-header">
                <div className="section-top-details">{application.appNo}</div>
                <div className="section-top-details name-section">{application.applicantName}</div>
                <div className="section-top-details">                 
                  ${application.totalActualAppIncentive
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  <div className="s-icon" title="Sync Now" onClick={() => this.updateAppSync(application.appNo)}>
                    <img className={!updatedTime ? "icon-rotate" : "icon"} src={SyncIcon} alt="Sync Icon" />
                  </div>
                </div>
              </div>
              <div className="section-below-header">
                <h2 className="section-title" dangerouslySetInnerHTML={{ __html: "Final Invoice Review" }}></h2>
                <div className="review-status-selection">
                  <a href={application.invoice && application.invoice.url} download target="_blank">
                    <Button title={"Download Invoice"} />
                  </a>
                </div>
              </div>
              <div className="invoice-review-container">
                <div className={`contractor-review-table ${!showMessage ? "review-mode" : ""}`}>
                  <div className="contractor-review-table-left">
                    <div className="contractor-review-header">
                      {showMessage ? "" : <div className="review-detail">S.No</div>}
                      <div className="review-detail">Invoice Item</div>
                      <div className="review-detail">Criteria</div>
                      <div className="review-detail">Question</div>
                      <div className="review-detail">Responses</div>
                    </div>
                    <div className="contractor-review-inner-container">{this.renderReviewDetails()}</div>
                  </div>
                  <div className="vertical-separator-box">
                    <span className="separator"></span>
                  </div>
                  <div className="contractor-review-table-right">
                    <p>
                      Please verify the accuracy of the note and the review decision before confirming the review. Note that this action
                      might send out an email to applicant or applicant representative.
                    </p>
                  </div>
                </div>
                <div className="fields-block">
                  <Input
                    label="Review Decision"
                    disabled={showMessage}
                    placeholder="Please select a Review Decision"
                    type="dropdown"
                    name="invoiceReviewDecision"
                    className="decision-options"
                    onChange={this.selectReviewerOption}
                    value={invoiceReviewDecision ? invoiceReviewDecision : undefined}
                    options={[
                      { value: "Return Invoice For Edits", label: "Return Invoice For Edits" },
                      { value: "Approve Invoice", label: "Approve Invoice" },
                    ]}
                    isSearchable={false}
                  />
                </div>
                <div className="review-notes-container">
                  <label>Notes:</label>
                  <Input disabled={showMessage} type={"richtext"} value={reviewNote} onChange={this.handleNotesChange} name="reviewNote" />
                </div>
              </div>
              {showMessage ? (
                <div className="review-btn-container">
                  <Button title={"Back"} className="edit-review" onClick={() => this.setState({ showMessage: false })} uppercase />
                  <Button title={"Confirm Review"} className="signup-btn" uppercase onClick={this.confrimReview} />
                </div>
              ) : (
                <div className="review-btn-container">
                  <Button title={"Cancel"} className="edit-review" onClick={closeActionModal} uppercase />
                  <Button
                    title={"Complete Review"}
                    disabled={
                      !invoiceReviewDecision ||
                      !utils.cleanHTMLText(reviewNote) ||
                      !review.questions[2].answer ||
                      !review.questions[5].answer
                    }
                    className="signup-btn"
                    uppercase
                    onClick={this.completeReview}
                  />
                </div>
              )}
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
